import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { store, persistedStore } from "./redux/store/store";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

// test mode
// const stripePromise = loadStripe(
//   "pk_test_51PkRc4RqJnG9uvAmJL4CttjdPb0qiA1KfzzoTh5qfnkAXAGYMcomrcn4An4Sd040LMuPth9OELC21pbxXdTSNEos00hcfF6j5W"
// );

// live mode
const stripePromise = loadStripe(
  "pk_tepk_live_51PkRc4RqJnG9uvAm6I8eRbbms6KfLhsQKdXkSNz3pNLTlrjMAGr5cJT7TgbaSekOB3MWCgMf8cOXXfnzduPm2SHM00ezvSApBK"
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistedStore}>
      <GoogleOAuthProvider clientId={clientId}>
        <Elements stripe={stripePromise}>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </Elements>
      </GoogleOAuthProvider>
    </PersistGate>
  </Provider>
);

reportWebVitals();
