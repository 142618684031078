// import React from "react";
// import { Navigate } from "react-router";
// const PrivateRoute = ({ children }) => {

//   let token = localStorage.getItem("token");
//   // console.log("Token:", token);
//   return <div>{token ? children : <Navigate to="/" />}</div>;
// };
// export default PrivateRoute;

import React from "react";
import { Navigate } from "react-router";
import { useSelector } from "react-redux";

const PrivateRoute = ({ children }) => {
  // Redux se user ka data lo
  const user = useSelector((state) => state.auth.user);

  // Token check karo
  const token = localStorage.getItem("token");

  // Gender check karo (Redux se ya localStorage se)
  const gender = user?.gender || localStorage.getItem("gender");

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  if (!gender) {
    return <Navigate to="/profile" replace />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
